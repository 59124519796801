<template>
  <b-overlay
    :show="busy"
    rounded="sm"
  >
    <b-overlay
      :show="show"
      no-wrap
    />
    <content-with-sidebar
      v-if="itemDetail.id"
      class="cws-container cws-sidebar-right blog-wrapper"
      :key="itemDetail.id"
    >
      <!-- content -->
      <div class="blog-detail-wrapper">
        <b-row>
          <!-- blogs -->
          <b-col cols="12">
            <b-card
              :img-src="itemDetail.img"
              img-top
              img-height="450"
              :img-alt="itemDetail.nama"
              :title="itemDetail.nama"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    :src="itemDetail.avatar"
                    :to="{ name: 'pages-profile-detail', params: {id: itemDetail.created_by }}"
                  />
                </b-media-aside>
                <b-media-body>
                  <small class="text-muted mr-50">by</small>
                  <small>
                    <b-link :to="{ name: 'pages-profile-detail', params: {id: itemDetail.created_by }}" class="text-body" v-if="itemDetail.created_by_user">{{ itemDetail.created_by_user.name }}</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text-muted">{{ itemDetail.datePost }}</small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small>
                    <b-badge
                      style="margin-right: 2px; margin-top: 2px;"
                      v-for="data in itemDetail.public_tag"
                      :key="data.id"
                      variant="success"
                    >
                      {{data.nama}}
                    </b-badge>
                  </small>
                </b-media-body>
              </b-media>
              <div class="my-1 py-25">
                <b-link               v-for="tag in itemDetail.tags"
                  :key="tag"
                >
                  <b-badge
                    pill
                    class="mr-75"
                    :variant="tagsColor(tag)"
                  >
                    {{ tag }}
                  </b-badge>
                </b-link>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div class="my-1 py-25">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="MapPinIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ itemDetail.lokasi }}</span>
                </div>
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ itemDetail.tanggal }}</span>
                </div>
                <!-- <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="FlagIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ itemDetail.flag }}</span>
                </div> -->
                <!-- <div class="d-flex align-items-center text-body"
                  v-if="kegiatanId == 1"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Aktif</span>
                </div>
                <div class="d-flex align-items-center text-body"
                  v-if="kegiatanId == 2"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Aktif</span>
                </div>
                <div class="d-flex align-items-center text-body"
                  v-if="kegiatanId == 3"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">Tidak Aktif</span>
                </div> -->
              </div>
              <div
                class="blog-content"
                v-html="itemDetail.deskripsi"
              />

              <div
                v-if="statusTombol == 2"
              >
                <div class="d-flex align-items-center text-body"
                  v-for="(linkitem) in itemDetail.link_kegiatan"
                  :key="linkitem.id"
                >
                  <feather-icon
                    icon="Link2Icon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">
                    <a :href="linkitem.link" target="_blank">{{ linkitem.nama }}</a>
                  </span>
                </div>
              </div>
              <hr class="my-2">

              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center mr-1">
                    <b-button
                      v-if="statusTombol == 0 && $can('join', 'kegiatan')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="join"
                    >
                      Join
                    </b-button>

                    <b-button
                      v-if="statusTombol == 1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="info"
                      class="mr-1"
                    >
                      Menunggu Persetujuan
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="mr-1"
                      v-if="statusTombol == 3"
                    >
                      Ditolak
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      v-if="statusTombol == 2"
                    >
                      Disetujui
                    </b-button>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <h6 class="section-label d-none">
          Nilai Kepuasanmu terhadap kegiatan ini.
        </h6>
        <b-card class="d-none">
          <b-row>
            <b-col sm="6" @click="rating" class="FormRatting">
              <AwesomeVueStarRating :star="this.star" :disabled="this.disabled" :maxstars="this.maxstars" :starsize="this.starsize" :hasresults="this.hasresults" :hasdescription="this.hasdescription" :ratingdescription="this.ratingdescription" />
            </b-col>
          </b-row>
          <div
            v-for="(employee) in employeeData"
            :key="employee.userFullName"
            class="employee-task d-flex justify-content-between align-items-center mt-1"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <b-avatar
                  rounded
                  size="42"
                  :src="employee.foto"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0">
                  {{ employee.name }}
                </h6>
                <small>
                  <AwesomeVueStarRating
                    :star="employee.nilai"
                    :disabled="true"
                    :maxstars="5"
                    starsize="xs"
                    :hasresults="false"
                    :hasdescription="false"
                  />
                </small>
              </b-media-body>
            </b-media>
            <div class="d-flex align-items-center">
              <small class="text-muted mr-75">{{ employee.duration }}</small>
            </div>
          </div>
        </b-card>

        <!--/ blogs -->
      </div>
      <!--/ content -->

      <!-- sidebar -->
      <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
      >
        <!-- recent posts -->
        <div class="blog-recent-posts">
          <h6 class="section-label mb-75">
            Kegiatan Lainnya
          </h6>
          <b-media
            v-for="(recentpost,index) in blogSidebar"
            :key="recentpost.id"
            no-body
            :class="index? 'mt-2':''"
          >
            <b-media-aside class="mr-2">
              <b-link
                :to="{ name: 'pages-kegiatan-detail', params: { id: recentpost.id } }"
              >
                <b-img
                  :src="recentpost.img"
                  width="100"
                  rounded
                  height="70"
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  class="text-body-heading"
                  :to="{ name: 'pages-kegiatan-detail', params: { id: recentpost.id } }"
                >
                  {{ recentpost.nama }}
                </b-link>
              </h6>
            </b-media-body>
          </b-media>
        </div>
        <!--/ recent posts -->

      </div>
    </content-with-sidebar>
    <template v-slot:overlay>
      <div
        v-if="processing"
        class="text-center p-4 bg-primary text-light rounded"
      >
        <b-icon
          icon="cloud-upload"
          font-scale="4"
        />
        <div class="mb-3">
          Processing...
        </div>
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div
        v-else
        ref="dialog"
        tabindex="-1"
        role="dialog"
        aria-modal="false"
        aria-labelledby="form-confirm-label"
        class="text-center p-3"
      >
        <b-card>
          <div
            v-if="(itemDetail.private === 1 || itemDetail.private === 3) && statusPrompt === 'join'"
          >
            <div>
              <strong id="form-confirm-label">Apakah Anda Yakin?</strong>
              <div v-if="itemDetail.private === 1">
                Kegiatan ini bersifat privat apabila ingin bergabung maka masukkan password.<br/>Silahkan masukkan masukkan password yang diberikan oleh admin.
              </div>
              <div v-if="itemDetail.private === 3">
                Kegiatan ini bersifat closed apabila ingin bergabung maka masukkan password.<br/>Silahkan masukkan masukkan password yang diberikan oleh admin.
              </div>
            </div>
            <b-form>
              <b-form-group>
                <b-form-input
                  v-model="joinPassword"
                  type="password"
                  placeholder="Password"
                />
              </b-form-group>
            </b-form>
          </div>
          <div
            v-else
          >
            <p><strong id="form-confirm-label">Apakah Anda Yakin?</strong></p>
          </div>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              Tidak
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              Ya
            </b-button>
          </div>
        </b-card>
      </div>
      <b-toast />
    </template>
  </b-overlay>
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton, BOverlay, BToast,
} from 'bootstrap-vue'
import router from '@/router'
import {
  getKegiatan, getAllKegiatan, insUserKegiatan, getListRatingKegiatan, updRatingKegiatan,
} from '@/api/kegiatan'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import AwesomeVueStarRating from 'awesome-vue-star-rating'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    // BCardText,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    AwesomeVueStarRating,
    ContentWithSidebar,
    BOverlay,
    BToast,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      joinPassword: null,
      busy: false,
      show: false,
      userId: null,
      search_query: '',
      statusPrompt: null,
      commentCheckmark: '',
      itemDetail: [],
      blogSidebar: {},
      kegiatanId: null,
      processing: null,
      statusTombol: 0,
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      star: 5, // default star
      ratingdescription: [
        {
          text: 'Buruk',
          class: 'star-poor',
        },
        {
          text: 'Dibawah Rata-Rata',
          class: 'star-belowAverage',
        },
        {
          text: 'Rata-Rata',
          class: 'star-average',
        },
        {
          text: 'Bagus',
          class: 'star-good',
        },
        {
          text: 'Luar Biasa',
          class: 'star-excellent',
        },
      ],
      hasresults: false,
      hasdescription: true,
      starsize: 'lg', // [xs,lg,1x,2x,3x,4x,5x,6x,7x,8x,9x,10x],
      maxstars: 5,
      disabled: false,
      rateNilai: 5,
      chartData: [],
      chartColor: [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary],
      chartSeries: [45, 65, 60, 35, 65, 80],
      employeeData: [
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
          userFullName: 'Andi Situmorang',
          stars: 3,
          duration: '9hr 20m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Dina Lorenzo',
          stars: 5,
          duration: '4hr 17m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          userFullName: 'Dodi Saputra',
          stars: 4,
          duration: '12hr 8m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Linda',
          stars: 2,
          duration: '3hr 19m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-16.jpg'),
          userFullName: 'Diana Jensen',
          stars: 5,
          duration: '9hr 50m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
          userFullName: 'Haryadi',
          stars: 5,
          duration: '4hr 48m',
        },
      ],
      /* eslint-disable global-require */
      defaultImage: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
      amres: localStorage.getItem('amres'),
    }
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.userId = userData.id
    if (router.currentRoute.params.id) {
      this.show = true
      this.kegiatanId = router.currentRoute.params.id
      const response = await getKegiatan(this.$route.params.id)
      this.itemDetail = response.data.data
      if (this.itemDetail.status_approval_id !== null) {
        this.statusTombol = this.itemDetail.status_approval_id
      }
      if (this.itemDetail.nilai !== null) {
        this.star = this.itemDetail.nilai
      }
      if (this.$can('manage', 'all')) {
        this.statusTombol = 2
      }
      if (this.itemDetail.user_proyek && this.itemDetail.user_proyek[0] && this.$can('update', 'kegiatan') && this.itemDetail.user_proyek[0].status_approval_id === 2) {
        this.statusTombol = 2
      }
      const responseSide = await getAllKegiatan(5)
      this.blogSidebar = responseSide.data.data
      this.blogSidebar.forEach((value, index) => {
        if (value.img === null) {
          this.blogSidebar[index].img = this.defaultImage
        } else {
          this.blogSidebar[index].img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${value.img}`
        }
      })

      if (this.itemDetail.img) {
        this.itemDetail.img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${this.itemDetail.img}`
      } else {
        this.itemDetail.img = this.defaultImage
      }

      this.listRating()

      this.show = false
    }
  },
  methods: {
    rating() {
      this.busy = true
      this.statusPrompt = 'rating'
    },
    async listRating() {
      this.employeeData = []
      const responseRating = await getListRatingKegiatan(this.$route.params.id)
      this.employeeData = responseRating.data.data
    },
    join() {
      this.busy = true
      this.statusPrompt = 'join'
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      // if (this.amres === 'true') {
      if (this.statusPrompt === 'join') {
        try {
          this.busy = false
          this.show = true
          let statusApprovalId = 1
          let statusTombol = 1
          if (this.itemDetail.private === 2) {
            statusApprovalId = 2
            statusTombol = 2
          }
          if (this.itemDetail.private === 3) {
            statusApprovalId = 2
            statusTombol = 2
          }
          const formData = {
            user_id: this.userId,
            kegiatan_id: this.$route.params.id,
            jenis_user_id: 1,
            status_approval_id: statusApprovalId,
            password: this.joinPassword,
          }
          const response = await insUserKegiatan(formData)
          if (response.data.status.code === '00') {
            this.statusTombol = statusTombol
            if (statusTombol === 1) {
              this.makeToast('success', 'Permintaan Anda menunggu persetujuan Admin!')
            }
            if (statusTombol === 2) {
              this.$router.go(this.$router.currentRoute)
            }
          } else {
            this.makeToast('danger', response.data.data)
          }
          this.show = false
        } catch (error) {
          this.makeToast('danger', JSON.stringify(error.response.data))
          this.show = false
        }
      } else {
        this.busy = false
        this.show = true

        const starnumber = document.querySelectorAll('.FormRatting .rating .list .active').length
        // const rateStar = JSON.parse(localStorage.getItem('rateStar'))
        const formData = new FormData()
        formData.append('kegiatan_id', this.$route.params.id)
        formData.append('user_kegiatan_id', this.userId)
        formData.append('nilai', starnumber)
        await updRatingKegiatan(formData)
        this.show = false
        this.listRating()
      }
      // } else {
      //   this.busy = false
      //   this.show = false
      //   this.showMsgBoxAmres()
      // }
    },
    changeData(id) {
      router.push({ name: 'pages-kegiatan-detail', params: { id } })
      this.$http.get('/blog/list/kegiatan').then(res => {
        res.data.forEach(element => {
          if (id.toString() === element.id.toString()) {
            this.itemDetail = element
            this.statusTombol = element.status
          }
        })
      })
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
    showMsgBoxAmres() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxOk('Mohon melengkapi Data Pengguna terlebih dahulu.', {
          title: 'Informasi Kelengkapan',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Tutup',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
